import { Link } from "gatsby";
import React from "react";
import DarkMode from "./DarkMode";

interface HeaderProps {
  siteTitle?: string;
}

const Header: React.FC<HeaderProps> = ({ siteTitle = "" }) => (
  <header>
    <nav className="sm:flex justify-between p-6">
      <Link to="/" className="font-bold font-serif text-2xl mb-3">
        {siteTitle}
      </Link>

      <div className="mt-3 sm:mt-0">
        <Link
          to="/blog/"
          className="inline-block hover:underline"
          activeClassName="font-semibold"
        >
          Blog
        </Link>
        <Link
          to="/projects/"
          className="inline-block mx-4 hover:underline"
          activeClassName="font-semibold"
        >
          Projects
        </Link>
        <Link
          to="/contact/"
          className="inline-block hover:underline"
          activeClassName="font-semibold"
        >
          Contact
        </Link>
        <DarkMode className="ml-4" />
      </div>
    </nav>
  </header>
);

export default Header;
