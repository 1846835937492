import React from "react";
import DayNightSVG from "images/svg/dark-mode.svg";

interface DarkMode {
  className?: string;
}

const DarkMode: React.FC<DarkMode> = ({ className = "" }) => {
  return (
    <DayNightSVG
      className={`inline-block cursor-pointer ${className}`}
      onClick={toggleDarkMode}
    />
  );
};

function toggleDarkMode() {
  const isDark = !!document.querySelector("html")?.classList.contains("dark");

  try {
    if (isDark) {
      document.querySelector("html")?.classList.remove("dark");
      localStorage.setItem("ogTheme", "light");
    } else {
      document.querySelector("html")?.classList.add("dark");
      localStorage.setItem("ogTheme", "dark");
    }
  } catch (err) {
    console.error(err);
  }
}

export default DarkMode;
