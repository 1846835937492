/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useSiteMetadata } from "hooks/useSiteMetadata";
import React from "react";
import Header from "./header";

interface LayourProps {
  children?: React.ReactNode;
}

const Layout: React.FC = ({ children }: LayourProps) => {
  const metadata = useSiteMetadata();

  return (
    <div className="wrapper">
      <Header siteTitle={metadata.title} />
      <main className="flex flex-grow">{children}</main>
      <footer className="p-6 text-sm text-right flex justify-center">
        <div className="mr-4 text-blue-600 dark:text-blue-300 hover:underline">
          <a href="http://eepurl.com/hm89sn">Subscribe to my Newsletter</a>
        </div>
        <div className="mr-4 text-blue-600  dark:text-blue-300 hover:underline">
          <a href="https://twitter.com/gerlv">Twitter: @gerlv</a>
        </div>
        <div>&copy; {new Date().getFullYear()} Oleg Gera.</div>
      </footer>
    </div>
  );
};

export default Layout;
