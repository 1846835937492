/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: {
    name: string;
    content: string;
  }[];
  title: string;
  image?: string;
  article?: boolean;
}

function SEO({
  description = "",
  lang = "en",
  meta = [],
  title,
  image,
  article = false,
}: SEOProps): React.ReactElement | null {
  const { pathname } = useLocation();

  const {
    site,
    file: { publicURL: defaultImage },
  } = useStaticQuery(
    graphql`
      query {
        file(
          name: { eq: "social-og" }
          ext: { eq: ".png" }
          dir: { regex: "/images/social/" }
        ) {
          publicURL
        }

        site {
          siteMetadata {
            title
            description
            author
            fathomDomain
            fathomTrackingId
            siteUrl: url
            twitterUsername
          }
        }
      }
    `
  );

  const siteUrl = site.siteMetadata?.siteUrl;
  const defaultTitle = site.siteMetadata?.title;

  const seo = {
    title: title || defaultTitle,
    description: description || site.siteMetadata.description,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ``}
      link={[
        {
          rel: "dns-prefetch",
          href: "//fonts.googleapis.com",
        },
        {
          rel: "preconnect",
          href: "https://fonts.gstatic.com",
          crossOrigin: "anonymous",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          name: `og:url`,
          content: seo.url,
        },
        {
          name: `og:image`,
          content: seo.image,
        },
        {
          name: `og:title`,
          content: title,
        },
        {
          name: `og:description`,
          content: seo.description,
        },
        {
          name: `og:type`,
          content: article ? `article` : `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.twitterUsername || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
      ].concat(meta)}
      script={[
        {
          src: site.siteMetadata.fathomDomain,
          site: site.siteMetadata.fathomTrackingId,
          defer: "defer",
          "data-spa": "auto",
          "data-canonical": "false",
        },
      ]}
    />
  );
}

export default SEO;
