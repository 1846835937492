import { graphql, useStaticQuery } from "gatsby";

interface SiteMetadataInterface {
  title: string;
  description: string;
}

export const useSiteMetadata = (): SiteMetadataInterface => {
  const { site } = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return site.siteMetadata;
};
